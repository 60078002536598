<template>
  <div class="classAward">
    <div class="flex-box">
      <div class="flex-box">
        <el-button type="success"
                   @click="addClassAward">+新增</el-button>
        <el-upload action="/api/base/importAward.do"
                   :headers="uploadHeader"
                   class="header-button"
                   name="uploadFile"
                   :on-success="uploadSuccess"
                   :data="{type:4}"
                   :show-file-list="false">
          <el-button type="primary">导 入</el-button>
        </el-upload>
        <el-button type="primary"
                   @click="downOtherAward(0)">下载导入模板</el-button>
        <!-- <el-button type="info"
                   @click="downOtherAward(1)">导 出</el-button> -->
      </div>
      <div>
        <!-- 奖状类型 -->
        <el-select v-model="queryLevel"
                   clearable
                   placeholder="级别">
          <el-option v-for="level in levelList"
                     :key="level.value"
                     :label="level.name"
                     :value="level.value"></el-option>
        </el-select>
        <!-- 年度 -->
        <term-selector placeholder="请选择学期"
                       @change="querySemesterChange"
                       class="select-box"
                       v-model="queryYear" />
        <!-- <el-select v-model="queryYear"
                   placeholder="请选择学期">
        </el-select> -->
        <el-button @click="loadClassAward(1)"
                   type="primary">查 询</el-button>
      </div>
    </div>
    <!-- table表格 -->
    <el-table :data="classAwardTable"
              style="width: 100%">
      <el-table-column label="学期"
                       prop="semester"
                       align="center"></el-table-column>
      <el-table-column label="班级"
                       prop="cname"
                       align="center"></el-table-column>
      <el-table-column label="奖项"
                       prop="name"
                       align="center"></el-table-column>
      <el-table-column label="颁奖单位"
                       prop="unit"
                       align="center"></el-table-column>
      <el-table-column label="级别"
                       prop="leaveStr"
                       align="center">
      </el-table-column>
      <el-table-column label="证书扫描件等证明材料"
                       align="center">
        <template slot-scope="scope">
          <el-image style="width: 100px; height: 100px"
                    v-if="scope.row.url"
                    :src="scope.row.photoUrl"
                    :preview-src-list="scope.row.photoUrlList">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="时间"
                       prop="opertime"
                       align="center"></el-table-column>
      <el-table-column label="操作"
                       width="180px"
                       align="center">
        <template slot-scope="scope">
          <el-button type="primary"
                     @click="editClassAward(scope.row)">编辑
          </el-button>
          <el-button type="danger"
                     @click="deleteClassAward(scope.row.id)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="prev,pager,next"
                   background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadClassAward"></el-pagination>
    <!-- 添加获奖班级与编辑获奖班级对话框 -->
    <el-dialog :title="classAwardForm.id ? '编辑获奖班级' : '添加获奖班级'"
               :visible.sync="classAwardFormVisible"
               width="600px">
      <div class="center">
        <el-form :model="classAwardForm"
                 ref="classAwardForm"
                 label-width="100px"
                 :rules="classAwardFormRules">
          <!-- 获奖学期 -->
          <el-form-item label="获奖学期："
                        prop="semesterId">
            <el-select v-model="classAwardForm.semesterId"
                       @change="loadAllClass(classAwardForm.semesterId)">
              <el-option v-for="semester in semesterList"
                         :key="semester.id"
                         :label="semester.name"
                         :value="semester.id"></el-option>
            </el-select>
          </el-form-item>
          <!-- 获奖年度 -->
          <el-form-item label="获奖年度："
                        prop="year">
            <el-date-picker v-model="classAwardForm.year"
                            type="year"
                            value-format="yyyy"
                            placeholder="选择获奖年度">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="获奖班级："
                        prop="cls">
            <el-cascader v-model="classAwardForm.cls"
                         placeholder="请选择班级"
                         :options="gradeList"></el-cascader>
          </el-form-item>
          <!-- 奖项名称 -->
          <el-form-item label="奖项名称："
                        prop="name">
            <el-input v-model="classAwardForm.name"></el-input>
          </el-form-item>
          <!-- 单位 -->
          <el-form-item label="颁奖单位："
                        prop="unit">
            <el-input v-model="classAwardForm.unit"></el-input>
          </el-form-item>
          <!-- 级别 -->
          <el-form-item label="级别："
                        prop="level">
            <el-select v-model="classAwardForm.level"
                       clearable
                       placeholder="请选择级别">
              <el-option v-for="level in levelList"
                         :key="level.value"
                         :label="level.name"
                         :value="level.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="证书扫描件等证明材料："
                        prop="fileUrl">
            <el-upload class="upload-demo"
                       action="/api/base/uploadByOss.do"
                       :headers="{Authorization:token}"
                       :limit="1"
                       :on-remove="onRemoveFile"
                       ref="upload"
                       :on-success="enclosureUploadSuccess"
                       :data="{path:'classAward',name:''}"
                       :show-file-list="true">
              <el-button size="small"
                         type="primary">点击上传</el-button>
            </el-upload>
            <el-button type="primary"
                       size="small"
                       class="load-file"
                       @click="loadFile(classAwardForm.fileUrl)"
                       v-if="classAwardForm.fileUrl">下载附件</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 确认与取消 -->
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="classAwardFormVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="addAwardWiningSchoolsConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import termSelector from "@/components/selectors/termSelector";
export default {
  name: "classAward",
  components: { termSelector },
  data() {
    return {
      queryLevel: "",
      queryYear: "",
      page: { pageCount: 1, currentPage: 0 },
      // 添加获奖班级的表单
      classAwardForm: {
        cls: "",
        year: "",
        semesterId: "",
        name: "",
        unit: "",
        level: "",
        fileUrl: "",
      },
      // 添加获奖班级的表单验证规则对象
      classAwardFormRules: {
        cls: [{ required: true }],
        name: [{ required: true }],
        unit: [{ required: true }],
        level: [{ required: true }],
        fileUrl: [{ required: true }],
        year: [{ required: true }],
        semesterId: [{ required: true }],
      },
      // 添加获奖班级的表单的显示与隐藏
      classAwardFormVisible: false,
      // 获奖班级列表
      classAwardTable: [],
      teachersList: [],
      levelList: [
        { value: 1, name: "国家" },
        { value: 2, name: "省" },
        { value: 3, name: "市" },
        { value: 4, name: "校" },
      ],
      gradeList: [],
      semesterList: JSON.parse(sessionStorage.getItem("userInfo")).semesters,
      semesterId: -1,
      managementModule: JSON.parse(sessionStorage.getItem("userInfo")).nav1,
      roleType: false,
      uploadHeader: { Authorization: sessionStorage.getItem("token") },
      token: sessionStorage.getItem("token"),
      urlList: [],
    };
  },
  created() {
    //判断是否为管理员，804为班级获奖页面id
    for (let i = 0; i < this.managementModule.length; i++) {
      if (this.managementModule[i].id === 804) {
        this.roleType = true;
        break;
      }
    }
    for (let i = 0; i < this.semesterList.length; i++) {
      if (this.semesterList[i].status === "0") {
        this.classAwardForm.semesterId = this.semesterList[i].id;
      }
    }
    this.loadAllClass(this.classAwardForm.semesterId);
    this.loadClassAward(1);
  },
  methods: {
    loadAllClass(yearId) {
      const that = this;
      this.$post("/media/queryClassesByYear.do", {
        type: 2, //1班主任。2管理员
        yearid: yearId,
      })
        .then((res) => {
          let gradeList = [];
          res.data.forEach((grade) => {
            let children = [];
            grade.classlist.forEach((cls) => {
              children.push({ value: cls.id, label: cls.cname });
            });
            gradeList.push({ value: grade.id, label: grade.gname, children });
          });
          that.gradeList = gradeList;
        })
        .catch((err) => {
          that.$message.error(err.message);
        });
    },
    termChange(value) {
      this.classAwardForm.year = value;
    },
    // 获取初始页面数据
    loadClassAward(page) {
      const that = this;
      this.$post("/base/queryAwardClassList.do", {
        indexNo: page,
        yearid: this.queryYear,
        level: this.queryLevel,
        module_type: 1, //1指班级
      })
        .then((res) => {
          that.page.currentPage = res.data.indexNo;
          that.page.pageCount = res.data.pageCount;
          res.data.rows.forEach((item) => {
            item.photoUrl = that.$fileUploadDomain + item.url;
            item.photoUrlList = [];
            item.photoUrlList.push(that.$fileUploadDomain + item.url);
          });
          that.classAwardTable = res.data.rows;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 点击添加获奖班级按钮
    addClassAward() {
      this.classAwardFormVisible = true;
      this.$nextTick(() => {
        this.$refs.classAwardForm.resetFields();
        this.classAwardForm.id = "";
      });
    },
    // 点击编辑获奖班级按钮
    editClassAward(row) {
      // 显示对话框
      this.classAwardFormVisible = true;
      // 把数据赋值给编辑表单
      this.$nextTick(() => {
        this.classAwardForm.id = row.id;
        this.classAwardForm.name = row.name;
        this.classAwardForm.unit = row.unit;
        this.classAwardForm.level = row.level;
        this.classAwardForm.cls = row.classid;
        this.classAwardForm.fileUrl = row.url;
        this.classAwardForm.semesterId = row.yearid;
        this.classAwardForm.year = row.nd;
      });
    },
    // 点击删除获奖班级按钮
    deleteClassAward(id) {
      const that = this;
      this.$confirm("是否删除班级获奖?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/deleteAwardClass.do", { id })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.loadClassAward(1);
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 弹出框确定，提交数据
    addAwardWiningSchoolsConfirm() {
      // 表单预校验
      this.$refs.classAwardForm.validate((validate) => {
        // 校验通过
        if (validate) {
          let apiUrl;
          let data = {
            classid: this.classAwardForm.cls[1],
            name: this.classAwardForm.name,
            level: this.classAwardForm.level,
            unit: this.classAwardForm.unit,
            url: this.classAwardForm.fileUrl,
            yearid: this.classAwardForm.semesterId,
            nd: this.classAwardForm.year,
            module_type: 1, //1是班级获奖
          };
          this.$confirm("是否进行该操作", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post("/base/insertAwardClass.do", data)
                .then((res) => {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  this.classAwardFormVisible = false;
                  this.loadClassAward(1);
                })
                .catch((err) => {
                  this.classAwardFormVisible = false;
                  this.$message.error(err.message);
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        }
      });
    },
    downOtherAward(type) {
      const that = this;
      let paramas = new FormData();
      paramas.append("type", 4); //4下载班级获奖
      this.$postFile("/base/exportAwardMoBan.do", paramas)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导入";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    uploadSuccess(response, file, fileList) {
      if (response.type === 1) {
        this.$message({
          type: "success",
          message: "已上传文件",
        });
        this.addClassScheduleVisible = false;
        this.teacherSchedule();
      } else {
        this.$message({
          type: "warning",
          message: response.message,
        });
      }
    },
    querySemesterChange(value) {
      this.queryYear = value;
    },
    enclosureUploadSuccess(res, file) {
      this.classAwardForm.fileUrl = file.response.message;
    },
    loadFile(url) {
      window.open(this.$fileUploadDomain + url);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.classAwardForm.fileUrl = "";
    },
  },
};
</script>
<style scoped>
.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-box {
  margin: 0 10px;
}
.header-button {
  margin: 0 10px;
}
</style>